import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';
import Layout from 'templates/Layout';
import Content from 'templates/Content';
import Link from 'components/Link'

import Pager from 'global/Pager';

import DataProvider from 'providers/data';
import PressSelect from "components/templates/global/PressSelect";


const PressReleaseAll = (props) => {
	const { edges: pageEdges } = props.data.allContentPage;
	const { edges: nodes } = props.data.allPressRelease;
	let releasesArr = nodes.map(({ node }) => node);
	const pageNodes = pageEdges.map(({ node }) => node);
	let page = pageNodes[0];
	let { pageInfo } = props.data.allPressRelease;
	const { pageContext } = props;

	if ((!!props.providerResponse !== false && props.providerResponse.data.length > 0) && props.providerStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
		releasesArr = props.providerResponse.data;
		pageInfo = {
			hasPreviousPage: (props.providerResponse.page > 1),
			hasNextPage: (props.providerResponse.page < props.providerResponse.pages),
			perPage: props.providerResponse.pageSize,
			currentPage: props.providerResponse.page,
			totalCount: props.providerResponse.total,
			pageCount: props.providerResponse.pages,
		};
	}

	const pattern = ['/press/', '/press/page-$page/'];
	const [releases, setReleases] = React.useState(releasesArr)

	React.useEffect(() => {
		if (props.providerStatusText === "LOADED") {
			setReleases(releasesArr)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.providerStatusText])

	if (pageInfo.currentPage > pageInfo.pageCount && (props.providerStatusText === 'LOADED' || pageContext.liveRefresh === false)) {
		return <NotFound />
	}

	return (
		<>
			<Layout>
				<Content {...page} />
				<PressSelect />
				<section id="press">
					<div className="grid-container">
						<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
							{releases.length > 0 && releases.map(release => {
								let thumbnail = ""

								const card = {
									title: release.headline,
									image: release.photo ? release.photo : thumbnail,
									body: release.excerpt,
									link: `/press/${release.permalink}/`,
									linkText: 'Read More',
									date: release.showDate,
								}
								return (
									<div key={release.id} className={`cell`} style={{ marginBottom: '27px' }}>
										<div className="card">
											<Link to={card.link}>
												<h5>{card.title}</h5>
												{card.date && <p>{new Date(card.date).toLocaleDateString()}</p>}
												{card.body && <p>{card.body}</p>}
												<div className="button no-hover">{card.linkText}</div>
											</Link>
										</div>
									</div>
								)
							})}
							{releases.length === 0 && <div className="cell">No releases found, please try adjusting your search terms.</div>}
						</div>
					</div>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								{(pageInfo.pageCount > 1) && <Pager page={pageInfo.currentPage} pages={pageInfo.pageCount} pattern={pattern} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />}
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</>
	)
}

// export default PressReleaseAll
const getData = (props) => <DataProvider liveRefresh={props.pageContext.liveRefresh} type="PressRelease" apiParams={{ _page: props.pageContext.page, _pageSize: props.pageContext.limit, _sort: "showDate,headline", _order: "desc" }}><PressReleaseAll {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
query ($skip: Int!, $limit: Int!) {
	allContentPage(
	  filter: {uri: {eq: "/press/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		}
	  }
	}
	allPressRelease(
	  filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  sort: {order: [DESC, DESC], fields: [showDate, headline]}
	  limit: $limit
	  skip: $skip
	) {
	  edges {
		node {
		  id
		  permalink
		  headline
		  excerpt
		  photo
		  body
		  uri
		  showDate
		}
	  }
	  pageInfo {
		hasNextPage
		hasPreviousPage
		perPage
		currentPage
		totalCount
		pageCount
	  }
	}
  }
`